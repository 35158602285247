$knowledge: #feefb7; // todo

.ContractSegmentBullet {
    margin-left: -3.15rem;
    margin-top: 1rem;
    margin-right: 1.5rem;
}

.ContractSegmentBullet:hover + .ContractSegment {
    animation: blink 1.5s;
}

@keyframes blink {
    50% {
        background-color: $knowledge;
    }
}
