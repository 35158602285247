.ribbon {
    width: 150px;
    height: 150px;
    position: absolute;
    overflow: hidden;
    /* border: 1px solid; */

    @media screen and (max-width: 768px) {
        width: 130px;
        height: 130px;

        p {
            width: 175px;
        }
    }
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    content: "";
    z-index: 1;
    display: block;
    border: 7px solid #60576f;
    border-top-color: transparent;
    border-right-color: transparent;
}

.ribbon::before {
    top: -5px;
    left: 15px;
}

.ribbon::after {
    bottom: 15px;
    right: -4px;
}

.ribbon span {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 1;
    transform: rotate(45deg);
    width: 200px;
    padding: 10px 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
}
