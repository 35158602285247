.scan {
    animation: scan 2s infinite linear;
    position: relative;
    transform-box: fill-box;
    transform-origin: top right;
}

.puased {
    animation-play-state: paused !important;
}

@keyframes scan {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}

.p1 {
    animation: 2s ease-in 0.3s both infinite show;
}
.p2 {
    animation: 2s ease-in 1.2s both infinite show;
}
.p3 {
    animation: 2s ease-in 1.4s both infinite show;
}

@keyframes show {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    30% {
        opacity: 0.4;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
