.DisplayOptions {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

.Header {
    box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.1);
}

._EditBox {
    border: none;
    background: transparent;
    display: block;
}

._EditBoxBorder {
    display: inline-block;
    width: 0px;
    height: 1px;
    background: black;
    position: relative;
    -webkit-transition: all ease-in-out 0.15s;
    -o-transition: all ease-in-out 0.15s;
    transition: all ease-in-out 0.15s;
    margin-left: 50%;
}

._EditBox:hover+._EditBoxBorder,
._EditBox:active+._EditBoxBorder,
._EditBox:focus+._EditBoxBorder {
    width: 100%;
    margin-left: 0%;
}

.ActiveButton {
    border-color: #1e6ae2 !important;
    color: #1e6ae2 !important;
}

.ImpOpactiy100 {
    opacity: 100 !important;
}