._ScrollbarNarrow {
    overflow-x: hidden;
    transition: all 0.3 ease-in-out;
    overflow-y: overlay;

    &::-webkit-scrollbar {
        width: 0.2rem;

        @media screen and (max-width: 768px) {
            width: 0.2rem;
        }
    }

    &::-webkit-scrollbar-track {
        background: #cccccc00;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #0000001f;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #0000002f;
    }
}

._ScrollbarSpecial {
    overflow-x: hidden;
    transition: all 0.3 ease-in-out;
    overflow-y: overlay;

    &::-webkit-scrollbar {
        width: 1rem;

        @media screen and (max-width: 768px) {
            width: 0.3rem;
        }
    }


    &::-webkit-scrollbar-track {
        background: #cccccc00;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #0000001f;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #0000002f;
    }
}
