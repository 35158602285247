.tracks {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 80%;
    margin-top: 2rem;
    overflow-x: scroll;
  }
  
  .tracks td, .tracks th {
    border: 1px solid #ddd;
    padding: 8px;
    min-width: 8rem;
    text-align: center;
  }
  
  .tracks tr:nth-child(even){background-color: #f2f2f2;}
  
  .tracks tr:hover {background-color: #ddd;}
  
  .tracks th {
    min-width: 8rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
  }
