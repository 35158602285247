#Editable {
    overflow: visible;
}

#Editable ol {
    display: block;
    list-style-type: decimal;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
#Editable ul {
    display: block;
    list-style-type: disc;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

/*make sure this style is synchronized with the one in html-to-pdf.go*/

#Editable p {
    margin-top: 1rem;
    line-height: 1.8;
}

#Editable h1, h2, h3 {
    margin-top: 0.5rem;
}

#Editable ol, ul {
    margin-bottom: -1rem;
}

#Editable ol ol {
    list-style-type: lower-latin;
}

#Editable ol ol ol {
    list-style-type: lower-roman;
}

#Editable ol li {
    margin-top: 1rem !important;
}

#Editable ul li {
    margin-top: 1rem !important;
}

/* h5 is special for printing and narrowing gaps*/
#Editable h5 {
    margin-top: 1rem;
    line-height: 0;
    /* margin-bottom: -1rem; */
}
