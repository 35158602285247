@mixin white-gradient {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

$animationSpeed: 80s;
$width: 28rem;

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc($width * (-7)))
    }
}

// Styling
.testimonials-carousel {
    background: white;
    margin: auto;
    overflow: hidden;
    position: relative;

    &::before,
    &::after {
        @include white-gradient;
        content: "";
        height: 100%;
        position: absolute;
        width: 6rem;
        z-index: 2;
    }

    &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }

    .slide-track {
        animation: scroll $animationSpeed linear infinite;
        display: flex;
        width: calc($width * 14);

        &:hover {
            animation-play-state: paused;
        }
    }

    .slide {
        width: $width;
    }
}