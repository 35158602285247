.ProgressBar {
    display: flex;
    flex-direction: column;
    height: min-content;
    margin-right: 1rem;
    background: repeating-linear-gradient(to bottom, transparent 0 2px, black 4px 6px) 1.255rem/1px 100% no-repeat;

    transition: width 0.3s ease-in-out;

    &.Full {
        width: 15rem;
        margin-right: 2.5rem;

        .SectionText {
            transition: all 0.3s ease-in-out;
            opacity: 1;
        }
    }

    &.Truncated {
        width: 3.5rem;

        margin-right: 0px;
        @media (min-width: 640px) {
            margin-right: 2rem;
        }

        .SectionText {
            font-size: 0px;
            opacity: 0;
        }
    }

    .Btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        min-width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        border: 0.25rem solid #9e9e9e;
        font-size: 1.125rem;
        font-weight: medium;
        background-color: white;

        &:hover {
            border: 0.25rem solid darken(#9e9e9e, 8%);
        }

        &.Active {
            border: 0.25rem solid #ffd215 !important;
            color: black;
            background-color: white;
        }

        &.Completed {
            background-color: #2177ff !important;
            border: 0.25rem solid #2177ff;
            color: white;
        }

        &.Small {
            width: 1.25rem;
            margin-left: 0.625rem;
            height: 1.25rem;
            min-width: 1.25rem;
            background-color: white;
        }
    }

    .BtnSquare {
        @extend .Btn;

        border-radius: 20%;
        height: 2.7rem;
        width: 2.7rem;
    }
}
