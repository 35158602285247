.SnackBars {
    position: fixed;
    bottom: 16px;
    left: 16px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.Snackbar {
    min-width: 250px; /* Set a default minimum width */
    background-color: #333; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 8px; /* Rounded borders */
    padding: 16px; /* Padding */

    &.success {
        background-color: rgb(56, 142, 60);
        color: white;
    }
    &.error {
        background-color: rgb(197, 41, 41);
        color: white;
    }
}
