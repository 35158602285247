.InputField {
    outline-color: #2177FF; //todo
}

.Cross {
    width: 100%; 
    text-align: center;
    border-bottom: 1px solid #d9e2ff; 
    color: #474747;
    line-height: 0.2em !important;
    margin: 10px 0 20px; 

    span { 
        background:#fff; 
        padding:0 10px; 
        font-size: 1rem;
    }
}

.or-separator {
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #d3d7da;
  }
  
  .vertical-line {
    border-left: 1px solid #d9e2ff;
    flex-grow: 1;
    width: 1px;
  }

._ErrorHeight {
    min-height: 2.5rem;
    height: auto;
}