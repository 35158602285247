@tailwind base;

@tailwind components;

@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Inter");

@font-face {
    font-family: "Gibson";
    src: url("../public/fonts/Gibson-Thin.otf");
    font-weight: 100;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Gibson";
    src: url("../public/fonts/Gibson-Light.otf");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Gibson";
    src: url("../public/fonts/Gibson-Regular.otf");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Gibson";
    src: url("../public/fonts/Gibson-Medium.otf");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Gibson";
    src: url("../public/fonts/Gibson-Semibold.otf");
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Gibson";
    src: url("../public/fonts/Gibson-Bold.otf");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "GibsonBook";
    src: url("../public/fonts/Gibson-Book.otf");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "SuezOne";
    src: url("../public/fonts/SuezOne-Regular.ttf");
    font-display: swap;
    font-style: normal;
}

html {
    font-size: 1rem;
    height: 100%;
    display: flex;
    flex-grow: 1;
}

.TheContract {
    font-size: calc(0.9vw + 0.2em);

    @media (max-width: 640px) {
        font-size: 14px;
    }
}

body {
    // todo: used for tooltip components, check for better solution
    overflow: hidden;
}

body>* {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;

    *:lang("en") {
        font-family: "Gibson", sans-serif;
    }

    *:lang("he") {
        font-family: "SuezOne", sans-serif;
    }

    p {
        margin-bottom: 0px;
    }

    a {
        color: #2177ff;
        cursor: pointer;
    }
}

// temporary fix popover z index
[data-radix-popper-content-wrapper] {
    z-index: 100 !important;
}

.clamp-text {
    position: relative;
    background-color: inherit;
    hyphens: auto;
    transition: .5s all ease-in-out;
}

.clamp-text::after {
    content: "";
    width: 3em;
    height: 2ch;
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;
    // background: linear-gradient(90deg, transparent, var(--bg));
    background: inherit; // it's easier to work with inheritance
    -webkit-mask-image: linear-gradient(to left, black, transparent);
    mask-image: linear-gradient(to left, black, transparent);
    transition: 0.2s;
}


.simple-page {
    font-family: 'Arial';
    line-height: 1.8;

    @apply px-12;

    * {
        @apply text-justify;
        @apply leading-7;
    }

    h1 {
        @apply text-4xl;
    }

    h2 {
        @apply text-3xl;
    }

    h3 {
        @apply text-2xl;
    }

    h4 {
        @apply text-xl;
    }

    a {
        @apply text-primary;
    }

    ol {
        counter-reset: item;

        li:before {
            content: counter(item) ". ";
            counter-increment: item;
        }

        .numbered-section::before {
            content: "Section " counter(item) "." !important;
        }

        ol {
            counter-reset: item2;

            li:before {
                content: counter(item) "." counter(item2) ". ";
                counter-increment: item2;
                margin-left: 1em;
            }

            ol {
                counter-reset: item3;

                li:before {
                    content: counter(item) "." counter(item2) "." counter(item3) ". ";
                    counter-increment: item3;
                    margin-left: 3em;
                }
            }
        }
    }

    ol::after {
        counter-set: item 0;
    }

    ul>li {
        margin-top: 1rem;
        margin-left: 20px;
        list-style-type: disc;
    }

    b {
        font-weight: 600;
    }

    p:not(:first-child) {
        margin-top: 1rem;
    }
}

.contract-text {
    animation: textFadeInAndOut 2s;
}

@keyframes textFadeInAndOut {
    0% {
        opacity: 0;
        display: none;
        background-color: transparent;
    }

    15% {
        opacity: 1;
        display: inline;
        background-color: rgba(190, 206, 254, 0.7)
    }

    30% {
        opacity: 1;
        display: inline;
        background-color: rgba(190, 206, 254, 0.7)
    }

    100% {
        opacity: 1;
        display: inline;
        background-color: rgb(255, 255, 255);
    }
}

.checkmark::before {
    display: inline;
    content: '✓';
    color: #2177ff;
    font-weight: 800;
}

@keyframes grow-shrink {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(2);
    }

    100% {
        transform: scale(1);
    }
}

.animate-grow-shrink {
    animation-name: grow-shrink;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
}

.delay-dot-2 {
    animation-delay: -0.8s;
}

.delay-dot-3 {
    animation-delay: -0.4s;
}

.templateBrandIconClass {
    fill: none;
    stroke: #000000;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

