.OverrideSig {
    display: flex !important;
    flex-direction: column;
    justify-items: center;
}

.Signature {
    background: #fafbfe;
    border-radius: 8px;
    border: solid 1px #d9e2ff;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 140px;
}
